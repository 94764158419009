<template>
  <v-container>
    <v-row :align="'center'" :justify="'center'">
      <v-stepper v-model="e1" style="max-width: 900px">
        <v-stepper-header>
          <v-stepper-step @click="e1 = 1" step="1" style="cursor: pointer">
            Данные
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e1 > 2"
            @click="e1 = 2"
            step="2"
            style="cursor: pointer"
          >
            Пароль
          </v-stepper-step>
          <v-divider />
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row>
              <v-col class="pt-2 pb-0" cols="4" md="4">
                <v-text-field v-model="first_name" dense label="Фамилия" />
              </v-col>
              <v-col class="pt-2 pb-0" cols="4" md="4">
                <v-text-field v-model="last_name" dense label="Имя" />
              </v-col>
              <v-col class="pt-2 pb-0" cols="4" md="4">
                <v-text-field v-model="middle_name" dense label="Отчество" />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="12">
                <v-text-field v-model="role" dense label="Должность" disabled />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="12">
                <v-text-field dense label="E-mail" v-model="email" />
              </v-col>
            </v-row>
            <v-btn color="primary" @click="saveFirst" :disabled="disabledBtn">
              Сохранить
            </v-btn>
            <v-btn text to="/"> Выход </v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form v-model="valid">
              <v-row>
                <v-col class="pt-2 pb-0" cols="12" md="12">
                  <v-text-field
                    v-model="old_password"
                    type="password"
                    dense
                    label="Текущий пароль"
                  />
                </v-col>
                <v-col class="pt-0 pb-0" cols="12" md="12">
                  <v-text-field
                    v-model="password"
                    dense
                    label="Новый пароль"
                    type="password"
                  />
                </v-col>
                <v-col class="pt-0 pb-0" cols="12" md="12">
                  <v-text-field
                    v-model="repeat_password"
                    dense
                    label="Подтвердить пароль"
                    type="password"
                  />
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                @click="changePassword"
                :disabled="disabledBtn"
              >
                Сохранить
              </v-btn>
              <v-btn text to="/"> Выход </v-btn>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-row>
  </v-container>
</template>

<script>
import { UsersApiUrls } from "@/services/accommodationRequests/user.api.js";
import toast from "../../../plugins/toast";

export default {
  name: "CreateSource",
  data() {
    return {
      disabledBtn: false,
      email: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      id: null,
      phone_number: null,
      valid: false,
      e1: 1,
      role: localStorage.getItem("role"),
      old_password: null,
      password: null,
      repeat_password: null
    };
  },
  async created() {
    let res = await UsersApiUrls.getProfile();
    this.first_name = res.first_name;
    this.last_name = res.last_name;
    this.middle_name = res.middle_name;
    this.email = res.email;
    this.id = res.id;
    this.phone_number = res.phone_number;
  },
  methods: {
    async saveFirst() {
      this.disabledBtn = true;
      let body = {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        phone_number: this.phone_number
      };
      try {
        await UsersApiUrls.setProfile(body);
        toast.success("Успешно изменено!");
        this.disabledBtn = false;
        this.$router.push("/");
      } catch (e) {
        this.disabledBtn = false;
      }
    },
    async changePassword() {
      this.disabledBtn = true;
      let body = {
        new_password1: this.password,
        new_password2: this.repeat_password,
        old_password: this.old_password
      };
      try {
        await UsersApiUrls.setPassword(body);
        toast.success("Успешно изменено!");
        this.disabledBtn = false;
        this.$router.push("/");
      } catch (e) {
        this.disabledBtn = false;
      }
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
