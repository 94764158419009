const axios = window.axios
const url = '/api/v2/user/employees/'
const url_all = '/api/v2/user/responsibles/'
const url_change = '/api/v2/user/employees/change-password/'
const url_profile = '/api/v2/user/employees/profile/'
const url_profile_edit = '/api/v2/user/employees/profile-edit/'


export const UsersApiUrls = {
  // all users
  async getUsers() {
    return (await axios.get(`${url_all}`)).data;
  },


  // employees сотрудники
  async getEmployees(params) {
    return (await axios.get(`${url}`, {
      params: params
    })).data;
  },
  async createEmployee(body) {
    return (await axios.post(`${url}`, body)).data;
  },
  async getEmployee(id) {
    return (await axios.get(`${url}${id}/`)).data;
  },
  async setEmployee(id, body) {
    return (await axios.patch(`${url}${id}/`, body)).data;
  },
  async deleteEmployee(id) {
    return (await axios.delete(`${url}${id}/`)).data;
  },


  // change the password
  async setPassword(body) {
    return (await axios.post(`${url_change}`, body)).data;
  },

  // profile
  async getProfile() {
    return (await axios.get(`${url_profile}`)).data;
  },

  // profile edit
  async setProfile(body) {
    return (await axios.put(`${url_profile_edit}`, body)).data;
  },


}